const UnderConstruction = () => {
    return (
        <div className="contact-area">
            <div className="container">
                <div className="row">
                    <div
                        className="col-md-10 col-lg-8 m-auto text-center"
                        data-aos="fade-up"
                    >
                        <h2 className="title">
                            We Are One Touring Community&apos;s Website is Under
                            Construction
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UnderConstruction;
